.appHeader-cont {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px 80px;
    gap: 8px;

    &__left {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;

        &__titeWrapper {
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 0px;
        }

        &__search {
            flex: 1;
            width: 100%;
            max-width: 650px;
            min-width: 200px;
        }
    }

    &__text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px
    }

    &__title {
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 50px;
    }

}

@media screen and (max-width: 1240px) {
    .appHeader-cont {
        padding: 0px 60px;
    }
}

@media screen and (max-width: 1080px) {
    .appHeader-cont {
        padding: 0px 40px;
    }
}

@media screen and (max-width: 900px) {
    .appHeader-cont {
        position: relative;
        padding: 0px 20px;

        &__left {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 0px;

            &__titeWrapper {
                justify-content: center;
                width: fit-content;
            }

            &__search {
                max-width: 900px;
                min-width: auto;
            }
        }

    }
}

@media screen and (max-width: 350px) {
    .appHeader-cont {
        &__title {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 50px;
        }

    }
}