.advertisement-cont {
    order: 2;
    display: flex;
    gap: 8px;
    height: fit-content;

    &__adsWraper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 500px;

        &__carousel {
            width: 500px;

            &__item {
                width: 100% !important;
                height: 160px;
                border-radius: 10px;
            }
        }

        &__card {
            width: 100%;
            border-radius: 8px;

            &__descriptionCont {
                display: flex;
                flex-direction: column;
                gap: 4px;
                justify-content: flex-start;
            }
        }

        &__media {
            width: 100%;
            display: flex;
            gap: 10px;
            flex-direction: column;

            &__title {
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
            }

            &__wraper {
                display: flex;
                gap: 8px;
                justify-content: space-between;

                &__cont {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    &__btn {
                        border-radius: 50%;
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 1500px) {
    .advertisement-cont {
        &__adsWraper {
            max-width: 400px;

            &__carousel {
                width: 400px;
            }

        }

    }
}

@media screen and (max-width: 1240px) {
    .advertisement-cont {
        &__adsWraper {
            max-width: 350px;

            &__carousel {
                width: 350px;
            }

        }

    }
}

@media screen and (max-width: 1000px) {
    .advertisement-cont {
        &__adsWraper {
            max-width: 300px;

            &__carousel {
                width: 300px;
            }

        }

    }
}

@media screen and (max-width: 900px) {
    .advertisement-cont {
        order: 1;

        &__adsWraper {
            max-width: 900px;

            &__carousel {
                width: auto;
            }
        }
    }
}

@media screen and (max-width: 360px) {
    .advertisement-cont {
        &__adsWraper {
            &__media {
                &__wraper {
                    flex-wrap: wrap;
                }
            }
        }

    }
}