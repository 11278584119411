.App-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 500px;

  .advertisement-cont__adsWraper__carousel .slick-prev,
  .advertisement-cont__adsWraper__carousel .slick-next {
    color: #FFF;
    transform: scale(1.7);
  }
}