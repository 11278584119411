.searchBar-cont {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 160px;

    &__content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 8px;

        &__titeWrapper {
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 0px;
        }
    }

    &__search {
        flex: 1;
        width: 100%;
        max-width: 590px;
        border-radius: 24px;
        min-width: 200px;
    }

    &__quickSearch{
        flex: 1;
        width: 100%;
        max-width: 590px;
        border-radius: 24px;
        min-width: 200px;
    }

    &__title {
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: 72px;
    }
}

@media screen and (max-width: 1240px) {
    .searchBar-cont {
        padding: 0px 120px;
    }
}

@media screen and (max-width: 1080px) {
    .searchBar-cont {
        padding: 0px 80px;
    }
}

@media screen and (max-width: 900px) {
    .searchBar-cont {
        padding: 0px 20px;
    }
}