.confirmModal-cont {
    width: 100%;
    max-width: 400px;
    border-radius: 8px;

    &__titleWrapper {
        display: flex;
        gap: 16px;
        width: 100%;

        &__title {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }

        &__icon {
            font-size: 24px;
        }
    }

    &__btn {
        width: 100%;
        max-width: 80px;
        padding: 0px 16px;
    }

    &__subTitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-left: 40px;
        display: inline-block;
        max-width: 400px;
    }
}

@media screen and (max-width: 600px) {
    .confirmModal-cont {
        max-width: 300px;
    }
}