.patientData-cont {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 160px;
    margin-top: 10px;

    &__creation {
        width: 100%;
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: space-between;
    }

    &__flexWrapper {
        width: 100%;
        display: flex;
        gap: 20px;
    }

    &__list {
        width: 100%;
        order: 1;

        &__item {
            width: 100%;

            &__meta {
                width: 100%;
            }
        }
    }


    &__pagination {
        width: 100;
        margin-top: auto;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media screen and (max-width: 1240px) {
    .patientData-cont {
        padding: 0px 120px;
    }
}

@media screen and (max-width: 1080px) {
    .patientData-cont {
        padding: 0px 80px;
    }
}

@media screen and (max-width: 900px) {
    .patientData-cont {
        padding: 0px 20px;
        gap: 10px;
        &__flexWrapper {
            gap: 30px;
            flex-direction: column;
        }
        &__list {
            order: 2;
        }
    }
}

@media screen and (max-width: 350px) {
    .patientData-cont {
        &__list {
            &__item {
                &__meta {
                    &__address {
                        width: 100%;
                        max-width: 150px !important;
                    }
                }
            }
        }

    }
}