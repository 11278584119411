.appFooter-cont {
    width: 100%;
    padding: 20px 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    &__textWrapper {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

@media screen and (max-width: 1240px) {
    .appFooter-cont {
        padding: 20px 60px;
    }
}

@media screen and (max-width: 1080px) {
    .appFooter-cont {
        padding: 20px 40px;
    }
}

@media screen and (max-width: 900px) {
    .appFooter-cont {
        padding: 20px 20px;
    }
}