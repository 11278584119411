.detailsHeader-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__detailsWrapper {
        width: 100%;
        display: flex;
        gap: 8px;
        justify-content: space-between;

        &__btn {
            width: 100%;
            max-width: 170px;
        }

        &__titleWrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 4px;

            &__title {
                font-size: 24px;
                line-height: 32px;
                font-weight: 600;
                font-style: normal;
            }
        }
    }

    &__reasonWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        &__optionsWrapper {
            display: flex;
            justify-content: space-between;
            gap: 8px;
            align-items: center;

            &__btnsWrapper {
                display: flex;
                gap: 8px;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .detailsHeader-cont {
        gap: 8px;

        &__detailsWrapper {
            flex-direction: column;
            gap: 8px;
        }

        &__reasonWrapper {
            &__optionsWrapper {
                &__btnsWrapper {
                    display: flex;
                    gap: 8px;
                    flex-wrap: wrap;
                }
            }
        }
    }
}