.register-cont {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto;


    &__btnsWrapper {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 6px;

        &__popOverContent {
            width: 100%;
            max-width: 150px !important;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &__btn {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 4px;
            }
        }
    }

    &__text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px
    }
}

@media screen and (max-width: 900px) {
    .register-cont {
        position: absolute;
        top: 50%;
        transform: translate(-20px,-106%);
        right: 0;
    }
}