.signIn-cont {
    border-radius: 8px;
    width: 100%;

    &__content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;

        &__titlesWrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;

            &__apptitleCont {
                display: flex;
            }

            &__boldTitle {
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                font-style: normal;
                cursor: default;
            }
        }

        &__btnsWrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 8px;

            &__nestedCont {
                width: 100%;
                max-width: 240px;
                display: flex;
                gap: 8px;
                justify-content: space-between;
            }
        }

        &__textWrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            &__text {
                font-size: 12px;
                line-height: 20px;
                font-style: normal;
                font-weight: 400;
            }
        }

        &__text {
            font-size: 12px;
            line-height: 20px;
            font-style: normal;
            font-weight: 400;
        }

        &__registerWrapper {
            width: 100%;
            max-width: 239px;
            display: flex;
            align-items: center;
            gap: 6px;

            &__text {
              cursor: pointer;
              width: fit-content;
            }
        }
    }
}

@media screen and (max-width: 350px) {
    .signIn-cont {
        &__content {
            &__titlesWrapper {
                &__boldTitle {
                    font-size: 20px;
                    line-height: 30px;
                }
            }

        }
    }
}