.commentSection-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__addComment {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        &__commentWrapper {
            width: 100%;
            display: flex;
            gap: 8px;
            align-items: flex-start;
        }

        &__addCont {
            width: 100%;
            display: flex;
            gap: 8px;
            justify-content: space-between;
        }
    }

    &__commentsCont {
        width: 100%;
        display: flex;
        gap: 16px;
        flex-direction: column;

        &__filters {
            width: 100%;

            &__dropDown {
                width: 100%;
                max-width: 100px;
                cursor: pointer;

                &__content {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }

        &__comments {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;

            &__content {
                width: 100%;
                display: flex;
                gap: 8px;
                align-items: flex-start;

                &__commentContent {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    max-width: calc(100% - 8px - 30px);
                }

                &__circle {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;

                    &__preview {
                        border-radius: 50%;
                    }
                }
            }

            &__btnWrapper {
                display: flex;
                gap: 8px;
                margin-top: 4px;

                &__dateWrapper {
                    display: flex;
                    gap: 4px;
                    align-items: center;
                    width: fit-content;
                    justify-content: center;
                }
            }
        }

        &__loadWrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@media screen and (max-width: 900px) {
    .commentSection-cont {
        &__addComment {
            @media screen and (max-width: 600px) {
                &__addCont {
                    flex-direction: column;

                    &__btn {
                        width: 100%;
                        max-width: 170px;
                    }
                }
            }
        }

        &__commentsCont {
            gap: 12px;

            &__comments {
                gap: 8px;
            }
        }
    }
}