.details-cont {
    width: 100%;
    border-radius: 8px;

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}