.login-cont {
    border-radius: 8px;
    width: 400px !important;

    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: center;
        margin: 12px auto 0;

        &__formItemWrapper {
            width: 100%;
            max-width: 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;

            &__formItem {
                width: 100%;
            }
        }

        &__clickableText {
            cursor: pointer;
            width: fit-content;
        }

        &__btnWrapper {
            width: 100%;
            max-width: 300px;
            display: flex;
            flex-direction: column;
            gap: 4px;

            &__textWrapper {
                width: 100%;
                display: flex;
                gap: 4px;
                align-items: center;
            }
        }

    }

    .ant-form-item {
        margin-bottom: 0;
    }
}