.registerOrg-cont {
    border-radius: 8px;
    width: 530px !important;

    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;

        &__formItemWrapper {
            width: 100%;
            display: flex;
            gap: 12px;
            align-items: flex-start;

            &__formItem {
                width: 100%;
            }
        }

        &__formItemWrapperUpload {
            width: 100%;

            &__formItem {
                width: 100%;

                &__upload {
                    &__btn {
                        height: 32px !important;
                        width: 100% !important;
                        padding: 0;
                        margin-top: 8px;
                    }
                }
            }

            .ant-upload-wrapper {
                display: inline-block;
                width: 100%;
            }

            .ant-upload-select {
                width: 100%;
            }
        }

        &__btn {
            margin-top: 12px;
        }
    }

    .ant-form-item {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 600px) {
    .registerOrg-cont {
        width: 100% !important;

        &__form {
            gap: 4px;

            &__formItemWrapper {
                flex-direction: column;
                gap: 8px;
                justify-content: center;
            }
        }
    }
}