.createPatient-cont {
    border-radius: 8px;
    width: 100%;

    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__formItemWrapper {
            width: 100%;
            display: flex;
            gap: 16px;
            align-items: flex-start;

            &__formItem {
                width: 100%;
                max-width: 250px;
            }
        }

        &__formItemWrapperUpload {
            width: 100%;

            &__formItem {
                width: 100%;

                &__upload {
                    &__btn {
                        height: 32px !important;
                        width: 100% !important;
                    }
                }
            }
        }
    }

    .ant-form-item {
        margin: 0px;
    }

    .ant-upload-wrapper {
        color: inherit;
        display: block;
        width: 100% !important;

        .ant-upload-select {
            width: 100%;
        }
    }

    .ant-upload-list-item-name {
        color: inherit;
    }
}

@media screen and (max-width: 550px) {
    .createPatient-cont {
        &__form {
            gap: 8px;

            &__formItemWrapper {
                gap: 8px;
                flex-direction: column;

                &__formItem {
                    max-width: 100%;
                }
            }
        }
    }
}